import React from 'react';
import { Container, Grid, Divider } from '@mui/material';

import PageLayout from '../../components/pageLayout/PageLayout';
import styles from './About.module.scss';

import owner_image from '../../assets/images/owner.jpg'
import activity1 from '../../assets/images/reformer.jpg'
import activity2 from '../../assets/images/activity2.jpg'
import activity3 from '../../assets/images/activity3.jpg'
import activity4 from '../../assets/images/activity4.jpg'
import activity5 from '../../assets/images/activity5.jpg'

const About: React.FC = () => {
  return (
    <PageLayout>
      <div className={`${styles.hero} flex justify-center items-center rounded bg-bottom`}>
        <Container maxWidth="lg">
          <p className='text-center text-5xl sm:text-6xl'>About Me</p>
        </Container>
      </div>
      <Container maxWidth="lg" className='my-28'>
        <p className='text-2xl sm:text-3xl text-left tracking-wider'>
          Indian Institute of Pilates
        </p>
        <p className='mt-10 text-lg sm:text-xl tracking-wider text-left'>
          Welcome to the Indian Institute of Pilates, where we believe in transforming fitness into a holistic and enriching experience. Founded by Divesh Mukhyara, a multi-certified fitness trainer, physiotherapist, and exercise physiologist, our school is dedicated to providing top-notch Pilates instructor training and comprehensive fitness programs.
          With over a decade of experience in the fitness industry, Divesh's expertise and passion drive our mission to promote overall well-being through structured and professional Pilates training. Our programs are designed to cater to both beginners and seasoned professionals, ensuring a supportive and innovative learning environment.
          At the Indian Institute of Pilates, we are committed to helping you achieve your fitness and professional goals. Join us to enhance your skills, build your career, and be part of a community that values health, wellness, and continuous growth.
        </p>
      </Container>
      <Container maxWidth="md" className={`my-20`}>
        <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
      </Container>
      <Container maxWidth="md" className={``}>
        <div className='text-3xl font-semibold text-center mt-20 mb-10'>
          About the Founder
        </div>
      </Container>
      <Container maxWidth="lg" className={`my-28`}>
        <div className='flex items-center justify-center'>
          <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row" }}>
            <Grid alignItems={"center"} justifyContent={"center"} className='flex items-center justify-center' item lg={4} xs={12} sm={12} md={12}>
              <img className={`${styles.founderImg} shadow`} src={owner_image} />
            </Grid>
            <Grid className='lg:mb-0 mb-20' item xs={12} lg={8} sm={12} md={12}>
              <p className='text-2xl sm:text-3xl text-center lg:text-left tracking-wider'>
                Divesh Mukhyara
              </p>
              {/* <p className='text-sm sm:text-md text-left tracking-wider'>
                Founder
              </p> */}
              <p className='mt-4 text-base sm:text-lg text-left'>
                Hello! I'm Divesh Mukhyara, a multi-certified fitness trainer, physiotherapist, and exercise physiologist with over a decade of experience in the fitness industry. My journey into the world of fitness and wellness began with a mission: to transform fitness training into a profession driven by passion, expertise, and business acumen. In 2017, I founded The Fit Dock Academy in Hyderabad, aiming to instill a positive sense of fitness through a structured and professional approach. Today, The Fit Dock has grown to five centers across the city, each committed to enhancing the fitness journey of our clients through innovative Pilates training and comprehensive fitness programs.
              </p>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="lg" className={`my-28`}>
        <div className='flex items-center justify-center'>
          <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row-reverse" }}>
            <Grid alignItems={"center"} justifyContent={"center"} className='flex items-center justify-center' item lg={4} xs={12} sm={12} md={12}>
              <img className={`${styles.founderImg} shadow`} src={activity1} />
            </Grid>
            <Grid className='lg:mb-0 mb-20' item xs={12} lg={8} sm={12} md={12}>
              <p className='text-2xl sm:text-3xl text-center lg:text-left tracking-wider'>
                My Expertise
              </p>
              <p className='mt-4 text-base sm:text-lg text-left'>
                Over the past 12 years, I've dedicated myself to helping patients manage and overcome various medical conditions through tailored fitness programs, including specialized Pilates exercises. My expertise lies in treating individuals with diabetes, thyroid issues, hypertension, and back pain, bringing them back to normalcy through targeted exercises and rehabilitation.
              </p>
              <p className='mt-4 text-base sm:text-lg text-left'>
                As an accredited exercise physiologist and a member of several esteemed organizations, including ESSA and the Indian Association of Physiotherapy, I supervise the evaluation, enhancement, and maintenance of fitness for a diverse clientele. My approach is results-oriented, focusing on personalized care and maintaining excellent relationships with clients and patients.
              </p>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="lg" className={`my-28`}>
        <div className='flex items-center justify-center'>
          <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row" }}>
            <Grid alignItems={"center"} justifyContent={"center"} className='flex items-center justify-center' item lg={5} xs={12} sm={12} md={12}>
              <img className={`${styles.founderImg} shadow`} src={activity2} />
            </Grid>
            <Grid className='lg:mb-0 mb-20' item xs={12} lg={7} sm={12} md={12}>
              <p className='text-2xl sm:text-3xl text-center lg:text-left tracking-wider'>
                Core Competencies
              </p>
              <ul className='mt-4 list-disc text-base sm:text-lg text-left '>
                <li>Assessing and evaluating physical disabilities</li>
                <li>Developing and reviewing treatment programs</li>
                <li>Analyzing and assisting in patient rehabilitation</li>
                <li>Managing pain and physical problems from illnesses, disabilities, and injuries</li>
                <li>Maintaining and updating patient records</li>
                <li>Collaborating with consulting physicians and other professionals</li>
                <li>Educating patients and their families about self-care and treatment interventions</li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="lg" className={`my-28`}>
        <div className='flex items-center justify-center'>
          <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row-reverse" }}>
            <Grid alignItems={"center"} justifyContent={"center"} className='flex items-center justify-center' item lg={5} xs={12} sm={12} md={12}>
              <img className={`${styles.founderImg} shadow`} src={activity3} />
            </Grid>
            <Grid className='lg:mb-0 mb-20' item xs={12} lg={7} sm={12} md={12}>
              <p className='text-2xl sm:text-3xl text-center lg:text-left tracking-wider'>
                My Passion
              </p>
              <p className='mt-4 text-base sm:text-lg text-left'>
                Fitness is more than just exercise; it’s about creating experiences that enrich the lives of my clients. At The Fit Dock, we don't see fitness as a must-do task but as an opportunity to foster overall well-being and a healthier lifestyle. My programs, including Pilates workouts, are designed to improve stamina, create a healthy ecosystem for achieving fitness goals, and develop overall well-being for all age groups. As a fitness coach and motivator, I've had the privilege of serving over 2000 clients, transforming work cultures in training studios and gyms, and consistently striving for excellence.
              </p>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="lg" className={`my-28`}>
        <div className='flex items-center justify-center'>
          <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row" }}>
            <Grid alignItems={"center"} justifyContent={"center"} className='flex items-center justify-center' item lg={5} xs={12} sm={12} md={12}>
              <img className={`${styles.founderImg} shadow object-left`} src={activity4} />
            </Grid>
            <Grid className='lg:mb-0 mb-20' item xs={12} lg={7} sm={12} md={12}>
              <p className='text-2xl sm:text-3xl text-center lg:text-left tracking-wider'>
                Professional Achievements
              </p>
              <ul className='mt-4 list-disc text-base sm:text-lg text-left '>
                <li>Built, trained, developed, and inspired high-performing teams</li>
                <li>Improved service efficiency and organizational consistency</li>
                <li>Preferred coach and motivator for prestigious clients</li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="lg" className={`my-28`}>
        <div className='flex items-center justify-center'>
          <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row-reverse" }}>
            <Grid alignItems={"center"} justifyContent={"center"} className='flex items-center justify-center' item lg={5} xs={12} sm={12} md={12}>
              <img className={`${styles.founderImg} shadow object-left`} src={activity5} />
            </Grid>
            <Grid className='lg:mb-0 mb-20' item xs={12} lg={7} sm={12} md={12}>
              <p className='text-2xl sm:text-3xl text-center lg:text-left tracking-wider'>
                Leadership and Business Acumen
              </p>
              <ul className='mt-4 list-disc text-base sm:text-lg text-left '>
                <li>Teamwork and staff leadership</li>
                <li>Fitness program development</li>
                <li>Pilates and group fitness plans</li>
                <li>Strength training and exercise bootcamps</li>
                <li>Nutrition planning</li>
                <li>Business development for gyms and fitness studios</li>
                <li>Flexible training schedules</li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </Container>

      <Container maxWidth="lg" className={`mb-16`}>
        <div className='flex w-full rounded text-gray-100 items-center justify-center py-6 px-4' style={{ backgroundColor: "#343D3B" }}>
          <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row-reverse" }}>
            <Grid className='lg:mb-0 mb-20' item xs={12} lg={7} sm={12} md={12}>
              <p className='text-xl sm:text-2xl text-center tracking-wider'>
                Join me on this journey to transform fitness into an enriching and enjoyable experience. Let's work together to achieve your fitness goals and lead a healthier, happier life.
              </p>
            </Grid>
          </Grid>
        </div>
      </Container>
    </PageLayout>
  );
};

export default About;
