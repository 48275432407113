import React from 'react';
import PageLayout from '../../components/pageLayout/PageLayout';
import styles from './Home.module.scss';
import { Container, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';

import headerImg from '../../assets/images/home.jpg';
import reformer from '../../assets/images/reformer.jpg';
import home3 from '../../assets/images/sixth.jpg'
import home4 from '../../assets/images/home4.jpg';

const Home: React.FC = () => {

  const testimonials = [
    {
      name: 'Aditi Sharma',
      role: 'Certified Pilates Instructor',
      content: 'Joining the Indian Institute of Pilates was the best decision I ever made for my career. The comprehensive teacher training program equipped me with the skills and confidence to become a successful instructor.',
    },
    {
      name: 'Rajiv Kumar',
      role: 'Studio Owner',
      content: 'As a studio owner, the quality of equipment is crucial for my business. The Pilates reformers and chairs from the Indian Institute of Pilates are top-notch. My clients love them, and they have significantly enhanced the overall experience at my studio.',
    },
    {
      name: 'Sneha Gupta',
      role: 'Fitness Enthusiast',
      content: 'I have been practicing Pilates for years, but it wasn\'t until I joined the Indian Institute of Pilates that I truly understood the depth and benefits of this practice. The personalized guidance and tailored programs have improved my strength and flexibility tremendously.',
    },
  ];

  return (
    <PageLayout>
      <div className={`${styles.hero} flex justify-center items-center rounded bg-bottom`}>
        <Container maxWidth="lg">
          <div className='flex justify-center flex-col'>
            <p className='text-3xl sm:text-5xl text-center md:text-left tracking-wider'>
              Elevate Your Pilates <br /> Journey with Us
            </p>
            <p className='mt-10 text-xl sm:text-2xl text-center md:text-left tracking-wider'>
              Welcome to the Indian Institute of Pilates,<br /> where your passion for Pilates meets unparalleled expertise and innovation.
            </p>
          </div>
        </Container>
      </div>
      <Container maxWidth="md" className={``}>
        <div className='text-2xl lg:text-3xl font-semibold text-center my-28'>
          Why Choose the Indian Institute of Pilates?
        </div>
      </Container>
      <Container maxWidth="sm" className={`mb-16`}>
        <p className='text-base lg:text-lg tracking-wider'>
          <span className='font-semibold'>Expert Guidance and Experience: </span> Led by Divesh Mukhyara, our school brings decades of experience in the Pilates industry. Our comprehensive programs are designed to equip you with the skills and knowledge needed to become a successful Pilates instructor or enhance your existing practice.
        </p>
      </Container>

      <Container maxWidth="lg" className='mb-16'>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"center"} direction={{ xs: "column", sm: "column", md: "row", lg: "row" }} className={styles.imageGrid}>
          <Grid item xs={6} sm={6} md={6}>
            <img src={reformer} className={`${styles.imagePlaceholder} rounded shadow-xl object-cover`} />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="sm" className={`mb-16`}>
        <p className='text-base lg:text-lg tracking-wider'>
          <span className='font-semibold'>Transformative Teacher Training: </span>Our teacher training program is perfect for those seeking a rewarding career in Pilates. Whether you are a fitness enthusiast or a licensed healthcare provider, our training will elevate your practice and open new doors for your career.
        </p>
      </Container>

      <Container maxWidth="lg" className='mb-16'>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"center"} direction={{ xs: "column", sm: "column", md: "row", lg: "row" }} className={styles.imageGrid}>
          <Grid item xs={6} sm={6} md={6}>
            <img src={home3} className={`${styles.imagePlaceholder} rounded shadow-xl object-cover object-top`} />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="sm" className={``}>
        <p className='text-base lg:text-lg tracking-wider'>
          <span className='font-semibold'>Premium Pilates Equipment: </span>Discover our range of high-quality Pilates equipment, including reformers, Pilates chairs, and ladder barrels. Each piece is crafted with precision and care to ensure you have the best tools for your practice, whether at home or in a commercial studio.
        </p>
      </Container>

      <Container maxWidth="md" className={`my-20`}>
        <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
      </Container>

      <Container maxWidth="md" className={``}>
        <div className='text-2xl lg:text-3xl font-semibold text-center my-20'>
          Explore Our Programs
        </div>
      </Container>

      <Container maxWidth="sm" className={`mb-16`}>
        <p className='text-base lg:text-lg tracking-wider'>
          <span className='font-semibold'>Comprehensive Teacher Training Program: </span> Join our transformative Pilates teacher training program and become a certified instructor. Our curriculum covers everything from basic anatomy and physiology to advanced Pilates techniques and equipment training.
        </p>
      </Container>

      <Container maxWidth="lg" className='mb-16'>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"center"} direction={{ xs: "column", sm: "column", md: "row", lg: "row" }} className={styles.imageGrid}>
          <Grid item xs={6} sm={6} md={6}>
            <img src={home4} className={`${styles.imagePlaceholder} rounded shadow-xl object-cover object-top`} />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="sm" className={`mb-16`}>
        <p className='text-base lg:text-lg tracking-wider'>
          <span className='font-semibold'>Diverse Pilates Classes </span> We offer a variety of classes for all skill levels. Whether you're a beginner or an advanced practitioner, our classes are designed to help you improve your strength, flexibility, and overall well-being.
        </p>
      </Container>

      <Container maxWidth="md" className={`my-20`}>
        <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
      </Container>

      <Container maxWidth="md" className={``}>
        <div className='text-2xl lg:text-3xl font-semibold text-center my-20'>
          Shop the Best Pilates Equipment
        </div>
      </Container>

      <Container maxWidth="sm" className={`mb-16`}>
        <p className='text-base lg:text-lg tracking-wider'>
          <span className='font-semibold'>High-Quality Pilates Equipment: </span> Upgrade your Pilates practice with our premium range of equipment, including reformers, Pilates chairs, and ladder barrels. Each piece is meticulously designed to provide durability and optimal performance.
        </p>
      </Container>

      <Container maxWidth="lg" className='mb-16'>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"center"} direction={{ xs: "column", sm: "column", md: "row", lg: "row" }} className={styles.imageGrid}>
          <Grid item xs={6} sm={6} md={6}>
            <img src={headerImg} className={`${styles.imagePlaceholder} rounded shadow-xl`} />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="sm" className={`mb-16`}>
        <p className='text-base lg:text-lg tracking-wider'>
          <span className='font-semibold'>Beyond Equipment Sales </span> We understand that setting up a successful Pilates studio involves more than just equipment. Our expertise and experience help you make informed decisions on the right combination of equipment for your needs and future growth.
        </p>
      </Container>

      <Container maxWidth="md" className={`my-20`}>
        <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
      </Container>

      <Container maxWidth="md" className={``}>
        <div className='text-2xl lg:text-3xl font-semibold text-center mt-20 mb-10'>
          Join Our Vibrant Community
        </div>
      </Container>

      <Container maxWidth="sm" className={`mb-16`}>
        <p className='text-base lg:text-lg tracking-wider text-center'>
          At the Indian Institute of Pilates, we are more than just a training center. We are a community of passionate individuals dedicated to promoting health and wellness through Pilates. Join us and be part of a supportive, inspiring, and encouraging environment.
        </p>
      </Container>

      <Container maxWidth="sm" className={`mb-16 ${styles.getTouch}`}>
        <div className='flex-col gap-4 flex justify-center items-center'>
          <p className='text-base lg:text-lg tracking-wider text-center font-semibold'>
            Get in Touch
          </p>
          <p className='text-base lg:text-lg tracking-wider text-center'>
            Have questions or need more information? Contact us today! Our Education Team is here to assist you.
          </p>
          <Button variant="contained" color="primary" className={styles.heroButton} onClick={() => {
            window.scrollTo(0, document.body.scrollHeight);
          }}>
            Get in Touch
          </Button>
        </div>
      </Container>

      <Container maxWidth="md" className={`my-20`}>
        <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
      </Container>

      <Container maxWidth="md" className={``}>
        <div className='text-2xl lg:text-3xl font-semibold text-center mt-20 mb-10'>
          Testimonials
        </div>
      </Container>

      <div className='flex items-center justify-center gap-5 lg:flex-row flex-col'>
        {
          testimonials.map((item) => (
            <div className='w-96' key={item.name}>
              <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                <svg aria-hidden="true" width="105" height="78" className="absolute top-6 left-6 fill-slate-100">
                  <path
                    d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z">
                  </path>
                </svg>
                <blockquote className="relative">
                  <p className="text-base lg:text-lg tracking-wide text-gray-900">
                    {item.content}
                  </p>
                </blockquote>
                <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                  <div>
                    <div className="font-display text-base text-gray-900">{item.name}</div>
                    <div className="mt-1 text-sm text-gray-500">{item.role}</div>
                  </div>
                  {/* <div className="overflow-hidden rounded-full bg-slate-50">
                    <img alt="" src={headerImg} className="h-14 w-14 object-cover" loading="lazy" width="56" height="56" />
                  </div> */}
                </figcaption>
              </figure>
            </div>
          ))
        }
      </div>
    </PageLayout>
  );
};

export default Home;
