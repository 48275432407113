import { Container, Divider } from '@mui/material';

import styles from './SuccessStories.module.scss';

import PageLayout from '../../components/pageLayout/PageLayout';

const SuccessStories: React.FC = () => {

    const caseStudies = [
        {
            background: "Sanjana, a fitness enthusiast from Coimbatore, was looking to deepen her understanding of Pilates and enhance her overall fitness routine. She decided to join the Indian School of Pilates to learn from the best.",
            outcome: "After completing the program, Sanjana reported significant improvements in her strength, flexibility, and overall well-being. She feels more confident in her Pilates practice and is excited to continue her fitness journey with the skills she has acquired.",
            solution: "Sanjana enrolled in our comprehensive Pilates training program, which provided her with in-depth knowledge and practical experience. She also had access to our state-of-the-art Pilates equipment.",
            name: "Sanjana",
            place: "Coimbatore"
        },
        {
            background: "Santosh, another fitness enthusiast from Juhu, wanted to incorporate Pilates into his fitness routine to improve his core strength and flexibility. He chose the Indian School of Pilates for its reputation and high-quality training programs.",
            outcome: "Within a few months, Santosh experienced noticeable improvements in his core strength and overall fitness. He appreciates the personalized approach and the supportive environment at the Indian School of Pilates, which has motivated him to continue practicing Pilates regularly.",
            solution: "Santosh joined our beginner to intermediate Pilates classes, where he received personalized attention and guidance from our experienced instructors. He also trained on our top-quality Pilates reformers and chairs.",
            name: "Santosh",
            place: "Juhu"
        }
    ]

    return <PageLayout>
        <div className={`${styles.hero} flex justify-center items-center rounded bg-bottom`}>
            <Container maxWidth="lg">
                <p className='text-center text-5xl sm:text-6xl'>Success Stories</p>
            </Container>
        </div>
        <Container maxWidth="md" className={``}>
            <div className='sm:text-3xl text-2xl text-center mt-20 mb-10'>
                Case Studies
            </div>
        </Container>
        <div className='flex items-center justify-center'>
            <div className='lg:w-10/12 w-full gap-5 lg:flex-row flex-col flex items-center justify-center'>
                {
                    caseStudies.map((item) => (
                        <div className='w-11/12' key={item.name}>
                            <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                                <figcaption className="relative mt-4 flex items-center justify-between border-b border-slate-100 pb-6 mb-6">
                                    <div>
                                        <div className="font-display text-lg lg:text-xl font-semibold text-gray-900">{item.name}</div>
                                        <div className="mt-1 text-sm text-gray-500">{item.place}</div>
                                    </div>
                                </figcaption>
                                {/* <svg aria-hidden="true" width="105" height="78" className="absolute top-36 left-6 fill-slate-100">
                                <path
                                    d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z">
                                </path>
                            </svg> */}
                                <blockquote className="relative">
                                    <h1 className="text-base lg:text-lg underline underline-offset-2 mb-2 tracking-wide text-gray-900">
                                        Background
                                    </h1>
                                    <p className="text-base lg:text-lg tracking-wide text-gray-900">
                                        {item.background}
                                    </p>
                                </blockquote>
                                <blockquote className="relative">
                                    <h1 className="text-base mt-6 lg:text-lg underline underline-offset-2 mb-2 tracking-wide text-gray-900">
                                        Solution
                                    </h1>
                                    <p className="text-base lg:text-lg tracking-wide text-gray-900">
                                        {item.solution}
                                    </p>
                                </blockquote>
                                <blockquote className="relative">
                                    <h1 className="text-base mt-6 lg:text-lg underline underline-offset-2 mb-2 tracking-wide text-gray-900">
                                        Outcome
                                    </h1>
                                    <p className="text-base lg:text-lg tracking-wide text-gray-900">
                                        {item.outcome}
                                    </p>
                                </blockquote>
                            </figure>
                        </div>
                    ))
                }
            </div>
        </div>
        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="md" className={``}>
            <div className='text-2xl sm:text-3xl text-center mt-20 mb-10'>
                Share Your Story
            </div>
        </Container>

        <Container maxWidth="sm" className={`mb-16`}>
            <p className='text-base lg:text-lg tracking-wider text-center'>
                Have you had a transformative experience with the Indian School of Pilates? We would love to hear your story! Sharing your journey can inspire others and highlight the benefits of Pilates. Contact us to share your success story.
            </p>
        </Container>
    </PageLayout>
}

export default SuccessStories;