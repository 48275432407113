import { useEffect } from "react";
import { Phone, Copy, Mail } from "react-feather";
import toast, { Toaster } from "react-hot-toast";
import { MdWhatsapp } from "react-icons/md";

const Footer = () => {
    
    const copyHandler = async (number: string, type?: "email") => {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(number);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = number;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand("copy");
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
        if (type === "email") {
            toast.success("Email Copied!");
        } else {
            toast.success("Phone Number Copied!");
        }
    };

    const handleMailRedirect = () => {
        const mailtoLink = `mailto:info@indianinstituteofpilates.com`;

        // Open the mailto link in a new tab (or in the default email client)
        window.location.href = mailtoLink;
    }

    return (
        <div
            className="divCenter flex-col text-white pt-16 w-full relative"
            style={{ backgroundColor: "#343D3B" }}
        >
            <div className="w-screen h-96 contact_us absolute opacity-20 "></div>
            <div className="divCenter w-full flex-col px-4 z-10">
                <div className="flex items-center flex-col justify-center">
                    <h2
                        data-aos="fade-up"
                        className="text-center font-normal mb-6 text-base lg:text-xl"
                        style={{ letterSpacing: "0.5em" }}
                    >
                        Contact Us
                    </h2>
                    <div className="flex gap-10 items-center justify-center">
                        <Phone />
                        <MdWhatsapp size={28} />
                    </div>
                    <div className={`flex md:flex-row flex-col gap-10 items-center mt-10`}>
                        <h1
                            onClick={() => copyHandler("8885040922")}
                            data-aos="fade-up"
                            className="text-center cursor-pointer flex items-center gap-4 font-base md:text-xl lg:text-xl text-base tracking-widest neoButtonRed"
                        >
                            8885040922
                            <Copy size={15} />
                        </h1>
                    </div>
                </div>
                <div className="flex items-center flex-col mt-10 justify-center">
                    <Mail />
                    <div
                        data-aos="fade-up"
                        className="text-center font-base md:text-xl lg:text-xl text-base divCenter mt-10"
                    >
                        <h1
                            onClick={handleMailRedirect}
                            data-aos="fade-up"
                            className="text-center cursor-pointer flex items-center gap-4 font-base md:text-xl lg:text-xl text-base tracking-widest neoButtonRed"
                        >
                                info@indianinstituteofpilates.com
                            <Copy size={15} />
                        </h1>
                    </div>
                </div>
            </div>
            <p
                className="mb-2 mt-10 text-center bottom-0 font-light text-sm md:w-2/3 tracking-wider mx-2"
            >
                © Copyright {new Date().getFullYear()} Indian Institute of Pilates, Inc. All rights
                reserved. Various trademarks held by their respective owners.
            </p>

            <Toaster position="top-right" />
        </div>
    );
};

export default Footer;