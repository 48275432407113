import React from 'react';
import Header from '../header/Header';
// import Footer from './Footer';
import styles from './PageLayout.module.scss';
import Footer from '../footer/Footer';
import { MdWhatsapp } from 'react-icons/md';

interface Props {
  children: React.ReactNode;
}

const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.mainContent}>
        {children}
      </main>
      <Footer />
      <div className='fixed lg:bottom-10 lg:right-10 right-6 bottom-4 rounded-full bg-white p-2 shadow-xl cursor-pointer' style={{ backgroundColor: "#23D366" }} onClick={() => { window.location.href = 'https://wa.me/918885040922' }}>
        <MdWhatsapp className='text-white lg:text-4xl text-3xl' />
      </div>
    </div>
  );
};

export default PageLayout;
