import { db } from './firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const submitApplication = async (formData: any) => {
    try {
        const docRef = await addDoc(collection(db, "applications"), formData);
        console.log("Document written with ID: ", docRef.id);
        return "done"
    } catch (e) {
        console.error("Error adding document: ", e);
        return e
    }
};

export { submitApplication };
