import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    TextField,
    Button,
    Grid,
    Box,
    Divider,
    Container,
} from '@mui/material';
import dayjs from 'dayjs';

import styles from './JoinUs.module.scss';
import PageLayout from '../../components/pageLayout/PageLayout';
import { submitApplication } from '../../services/formService';
import toast, { Toaster } from "react-hot-toast";

interface FormValues {
    firstName: string;
    lastName: string;
    birthdate: string;
    streetAddress: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    email: string;
    phone: string;
    about: string;
    whyInterested: string;
    howHeard: string;
    pilatesExperience: string;
    pilatesEquipment: string;
    injuries: string;
    degreesAndCertifications: string;
    workshops: string;
    pastTeachingExperience: string;
    whyEnroll: string;
    additionalInfo: string;
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    birthdate: Yup.string().required('Date of birth is required'),
    streetAddress: Yup.string().required('Street address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string()
        .required('ZIP code is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string()
        .matches(/^\d{10}$/, 'Invalid phone number')
        .required('Phone number is required'),
});

const JoinUs: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
        //@ts-ignore
        resolver: yupResolver(validationSchema),
    });

    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        setIsSubmitting(true);
        let res = await submitApplication(data);
        setIsSubmitting(false);
        if(res === "done"){
            setSubmitted(true);
        }else{
            toast.error("There seems to be a problem while submitting");
        }
    };

    return (
        <PageLayout>
            <div className={`${styles.hero} flex justify-center items-center rounded bg-bottom`}>
                <Container maxWidth="lg">
                    <p className='text-center text-5xl sm:text-6xl'>Join us</p>
                </Container>
            </div>
            <Container maxWidth="lg" className='my-28'>
                <p className='text-2xl sm:text-3xl text-left tracking-wider'>
                    Apply for Our Teacher Training Program
                </p>
                <p className='mt-10 text-lg sm:text-xl tracking-wider text-left'>
                    If you're ready to join the Indian Institute of Pilates, please take a moment to complete the application below. This information will help our Education Team determine if our program is the right fit for you. There is no obligation or payment required to apply. We want you to be confident and excited about your decision to join our program. Once we receive your application, a member of our Education Team will reach out to discuss the program in more detail and answer any questions you may have.
                </p>
            </Container>
            <Container maxWidth="md">
                {
                    !submitted ?
                        <div className={styles.container}>
                            <p className='md:text-3xl text-2xl mb-10 mt-10'>
                                Personal Information
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={8} md={6} lg={6}>
                                        <TextField
                                            fullWidth={true}
                                            label="First Name"
                                            variant="outlined"
                                            {...register('firstName')}
                                            error={!!errors.firstName}
                                            helperText={errors.firstName?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} lg={6}>
                                        <TextField
                                            fullWidth={true}
                                            label="Last Name"
                                            variant="outlined"
                                            {...register('lastName')}
                                            error={!!errors.lastName}
                                            helperText={errors.lastName?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} lg={6}>
                                        <TextField
                                            inputProps={{ max: dayjs().format('YYYY-MM-DD') }}
                                            type="date"
                                            fullWidth={true}
                                            label="Date of Birth"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            {...register('birthdate')}
                                            error={!!errors.birthdate}
                                            helperText={errors.birthdate?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} lg={6}>
                                        <TextField
                                            fullWidth={true}
                                            label="Phone"
                                            variant="outlined"
                                            {...register('phone')}
                                            error={!!errors.phone}
                                            helperText={errors.phone?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} lg={6}>
                                        <TextField
                                            fullWidth={true}
                                            label="Email"
                                            variant="outlined"
                                            {...register('email')}
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} lg={8}>
                                        <TextField
                                            fullWidth={true}
                                            label="Street Address"
                                            variant="outlined"
                                            {...register('streetAddress')}
                                            error={!!errors.streetAddress}
                                            helperText={errors.streetAddress?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} lg={8}>
                                        <TextField
                                            fullWidth={true}
                                            label="Address Line 2"
                                            variant="outlined"
                                            {...register('addressLine2')}
                                        />
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} sm={8} md={6} lg={6} >
                                        <TextField
                                            fullWidth={true}
                                            label="City"
                                            variant="outlined"
                                            {...register('city')}
                                            error={!!errors.city}
                                            helperText={errors.city?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} lg={6}>
                                        <TextField
                                            fullWidth={true}
                                            label="State"
                                            variant="outlined"
                                            {...register('state')}
                                            error={!!errors.state}
                                            helperText={errors.state?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth={true}
                                            label="ZIP Code"
                                            variant="outlined"
                                            {...register('zipCode')}
                                            error={!!errors.zipCode}
                                            helperText={errors.zipCode?.message}
                                        />
                                    </Grid>
                                </Grid>

                                <Container maxWidth="md" className={`my-20`}>
                                    <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
                                </Container>

                                <div className='md:text-3xl text-2xl mb-10'>
                                    Application Questions
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Tell us about you and your goals!"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            {...register('about')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Why are you interested in our Teacher Training Program?"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            {...register('whyInterested')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="How did you hear about us?"
                                            variant="outlined"
                                            {...register('howHeard')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="How long have you been practicing Pilates?"
                                            variant="outlined"
                                            {...register('pilatesExperience')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="What Pilates equipment do you have experience with?"
                                            variant="outlined"
                                            {...register('pilatesEquipment')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Do you have or have you had any injuries?"
                                            variant="outlined"
                                            {...register('injuries')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="List any degrees, licensing, certifications (include dates):"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            {...register('degreesAndCertifications')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="List workshops, seminars, and courses related to this work which you have attended (include dates):"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            {...register('workshops')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Have you ever taught any movement-based method? Please list dates & locations:"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            {...register('pastTeachingExperience')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Why do you want to enroll in the Indian Institute of Pilates Teacher Training Program?"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            {...register('whyEnroll')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Please provide any further information you feel relates to your abilities and readiness for the Indian Institute of Pilates Teacher Training Program:"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            {...register('additionalInfo')}
                                        />
                                    </Grid>
                                </Grid>

                                <Box className={styles.buttonContainer}>
                                    <Button fullWidth type="submit" variant="contained" disabled={isSubmitting} color="secondary">
                                        {isSubmitting ? "Loading...." : "Submit"}
                                    </Button>
                                </Box>
                            </form>
                        </div>
                        :
                        <p className='md:text-3xl text-center text-2xl mb-10 mt-10'>
                            Your form is submitted successfully!
                        </p>
                }
            </Container>
            <Toaster position="top-right" />
        </PageLayout>
    );
};

export default JoinUs;