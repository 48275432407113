export const moreNavLinks = [
    {
        name: 'Success Stories',
        route: '/success-stories'
    },
    {
        name: "Join us",
        route: "/join-us",
    },
    {
        name: "Start your Pilates Studio",
        route: "/start-your-pilate-studio"
    }
];