import { Container, Divider, Grid } from '@mui/material';

import PageLayout from "../../components/pageLayout/PageLayout";

import styles from './StartYourOwn.module.scss';
import { GiStarsStack } from "react-icons/gi";
import { FaUserCog } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import video from '../../assets/videos/studio.mp4';

const StartYourOwn = () => {

    const chooseUs = [
        {
            name: "Years of Experience",
            desc: "With a deep understanding of the Pilates industry, we have honed our expertise over the years, helping many entrepreneurs establish successful studios across the country.",
            icon: <GiStarsStack size={40} color={"#343D3B"} />
        },
        {
            name: "Expertise in Setup",
            desc: "From choosing the perfect location to designing your studio and sourcing the best equipment, we have the know-how to help you build a thriving business.",
            icon: <FaUserCog size={40} color={"#343D3B"} />
        },
        {
            name: "Your One-Stop Shop",
            desc: "We manage everything — studio layout, equipment procurement, marketing strategies, and even hiring. You can count on us as your complete solution for setting up and running a successful Pilates studio.",
            icon: <FaShop size={40} />
        }
    ]

    return (<PageLayout>
        <div className={`${styles.hero} flex justify-center items-center rounded bg-bottom`}>
            <Container maxWidth="lg">
                <p className='text-center text-5xl sm:text-6xl'>Start your Pilates Studio</p>
            </Container>
        </div>

        <Container maxWidth="lg" className='md:my-28 mt-16 flex items-center justify-center'>
            <div className='flex items-center justify-center'>
                <video
                    autoPlay
                    loop
                    muted
                    className={`rounded shadow-xl`}
                >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Container>

        <Container maxWidth="lg" className='md:my-28 mt-16 mb-28'>
            <p className='text-2xl sm:text-3xl text-left tracking-wider'>
                Open Your Own Pilates Studio Anywhere in India!
            </p>
            <p className='mt-10 text-lg sm:text-xl tracking-wider text-left'>
                Are you passionate about Pilates and dreaming of opening your own studio? We are here to make that dream a reality! Whether you're in a metropolitan city or a smaller town, we'll guide you every step of the way.
            </p>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-center tracking-wider'>
                Why Choose Us?
            </p>
            <div className='mt-14 flex items-center justify-center flex-wrap gap-10'>
                {
                    chooseUs.map((item) => (
                        <div className='flex items-center justify-center flex-col w-full lg:w-5/12 gap-4'>
                            <div>{item.icon}</div>
                            <p className='text-center tracking-wider text-lg sm:text-xl'>{item.name}</p>
                            <p className='text-center text-base sm:text-lg'>{item.desc}</p>
                        </div>
                    ))
                }
            </div>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className={`mb-16`}>
            <div className='flex w-full rounded text-gray-100 items-center justify-center py-6 px-4' style={{ backgroundColor: "#343D3B" }}>
                <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "column", lg: "row-reverse" }}>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={7} sm={12} md={12}>
                        <p className='text-xl sm:text-2xl text-center tracking-wider'>
                            Let us handle the logistics, so you can focus on what matters most: sharing the joy of Pilates with your community.
                        </p>
                    </Grid>
                </Grid>
            </div>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="md" className={``}>
            <div className='text-2xl sm:text-3xl text-center mt-20 mb-10'>
                Get in Touch Today!
            </div>
        </Container>

        <Container maxWidth="sm" className={`mb-16`}>
            <p className='text-base lg:text-lg tracking-wider text-center'>
                Ready to bring your Pilates studio to life? Contact us to learn more about how we can help you start your own branch.
            </p>
        </Container>

    </PageLayout>
    )
}

export default StartYourOwn