import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB9eHhzG8YKlFFSI2CWnrdWRH09hEi-I-M",
  authDomain: "indian-school-of-pilates.firebaseapp.com",
  projectId: "indian-school-of-pilates",
  storageBucket: "indian-school-of-pilates.appspot.com",
  messagingSenderId: "423536258074",
  appId: "1:423536258074:web:9afaba1759b5fd13a9b5a3",
  measurementId: "G-D4SC89PM95"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };